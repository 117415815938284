// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-calendula-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-customer-blog/templates/calendula/Template.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-calendula-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-location-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/LocationTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-location-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-item-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-team-member-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/TeamMemberTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-team-member-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-boutique-jsx": () => import("./../../../src/pages/boutique.jsx" /* webpackChunkName: "component---src-pages-boutique-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-essex-location-jsx": () => import("./../../../src/pages/essex-location.jsx" /* webpackChunkName: "component---src-pages-essex-location-jsx" */),
  "component---src-pages-hair-lash-lounge-jsx": () => import("./../../../src/pages/hair-lash-lounge.jsx" /* webpackChunkName: "component---src-pages-hair-lash-lounge-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-jsx": () => import("./../../../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-product-recommendations-jsx": () => import("./../../../src/pages/product-recommendations.jsx" /* webpackChunkName: "component---src-pages-product-recommendations-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-spa-services-jsx": () => import("./../../../src/pages/spa-services.jsx" /* webpackChunkName: "component---src-pages-spa-services-jsx" */),
  "component---src-pages-st-albans-location-jsx": () => import("./../../../src/pages/st-albans-location.jsx" /* webpackChunkName: "component---src-pages-st-albans-location-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

